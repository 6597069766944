.main {
    width: 100%;
    height: 100%;
}

.head {
    width: 100%;
    height: 40px;
    display: flex;
}

.head2 {
    width: 50%;
    height: 100%;
    background-color: #ff86ad;
}

.social {
    width: 260px;
    background-color: white;
    margin-left: 100px;
    height: 85%;
    margin-top: 03px;
    border-radius: 16px;
}

.head3 {
    width: 50%;
    height: 100%;
    background-color: #5e4e6d;

}

.marks {
    width: 500px;
    height: 100%;
    margin-left: 100px;
}

.marks p {
    color: white;
    font-weight: bold;
    padding-top: 07px;
}




.socia2 {
    width: 100%;
    height: 50px;
    margin-top: 0px;
}

.socia2 a i {
    border-radius: 5px;
    width: 25px;
    height: 25px;
    margin-left: 15px;
    padding-left: 8px;
    padding-top: 7px;
    margin-top: 04px;
    color: white;
    font-size: 13px;
    transition: transform 0.2s ease;
    /* Added transition for smooth animation */
}

.socia2 a i:hover {
    transform: translateY(-5px);
    /* Move the icon 5px up on hover */
}

.present {
    width: 100%;
    height: 100px;
    display: flex;
    
}

.present2 {
    width: 23%;
    height: 100%;
    margin-left: 10px;
    
}

.present2 h1 {
    font-size: 17px;
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
}

.present2 p {
    margin-left: 80px;
    font-size: 14px;
    margin-top: -02px;
    color: black;
}

.logs {
    width: 50%;
    height: 100%;
    margin-top: 20px;
}

.logs img {
    margin-left: 80px;
    margin-top: 10px;
}

.logs h1 {
    margin-left: -0px;
    margin-top: 10px;
}

.disc {
    margin-left: 70px;
    margin-top: -50px;
    color: #ff86ad;
    font-size: 15px;
}


.icons {
    width: 60px;
    height: 80%;
    margin-top: 10px;
    border: 3px solid #5e4e6d;
    border-radius: 16px;
    text-align: center;
    padding-top: 15px;

}

.icons i {
    font-size: 40px;
}

.icons:hover {
    border: 3px solid #ff86ad;
    color: #ff86ad;
}

.mansi h1 {
    margin-left: -140px;
}

.mansi p {
    margin-left: 10px;
}




.garima h1 {
    padding-right: 150px;
}

.garima p {
    margin-left: -0px;
}



.calls h1 {
    padding-right: 190px;
}

.calls p {
    margin-left: 0px;
}

.riview {
    width: 100%;
    height: 300px;

    display: flex;
    margin-top: 10px;
}

.riview2 {
    width: 24.5%;
    height: 100%;
    margin-left: 05px;
    background-color: lightgray;

}

.riview2 h1 {
    font-size: 25px;
    text-align: center;
    margin-top: 20px;
}

.riview2 p {
    margin-left: 10px;
}

.riview2 i {
    margin-left: 30px;
    color: red;
}



@media screen and (min-width: 300px) and (max-width: 600px) {

    .main {
        width: 100%;
        height: 100%;
    }

    .head {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .head2 {
        width: 100%;
        height: 40px;
        background-color: #ff86ad;
    }

    .social {
        width: 260px;
        background-color: white;
        margin-left: 20px;
        height: 85%;
        margin-top: 03px;
        border-radius: 16px;
    }

    .head3 {
        width: 100%;
        height: 100%;
        background-color: #5e4e6d;

    }

    .marks {
        width: 90%;
        height: 100%;
        margin-left: 10px;
    }

    .marks p {
        color: white;
        font-weight: bold;
        padding-top: 07px;
    }




    .socia2 {
        width: 100%;
        height: 50px;
        margin-top: 0px;
    }

    .socia2 a i {
        border-radius: 5px;
        width: 25px;
        height: 25px;
        margin-left: 15px;
        padding-left: 8px;
        padding-top: 7px;
        margin-top: 04px;
        color: white;
        font-size: 13px;
        transition: transform 0.2s ease;
        /* Added transition for smooth animation */
    }

    .socia2 a i:hover {
        transform: translateY(-5px);
        /* Move the icon 5px up on hover */
    }

    .present {
        width: 100%;
        height: 100px;
        display: flex;
        display: none;
    }

    .present2 {
        width: 23%;
        height: 100%;
        margin-left: 10px;

    }

    .present2 h1 {
        font-size: 16px;
        text-align: center;
        margin-top: 10px;
        font-weight: bold;
    }

    .present2 p {
        margin-left: 80px;
        font-size: 14px;
        margin-top: -02px;
        color: #5e4e6d;
    }

    .logs {
        width: 50%;
        height: 100%;
        margin-top: 20px;
    }

    .logs img {
        margin-left: 80px;
        margin-top: 10px;
    }

    .logs h1 {
        margin-left: -20px;
        margin-top: 10px;
    }

    .disc {
        margin-left: 30px;
        margin-top: -40px;
        color: #ff86ad;
    }


    .riview {
        width: 100%;
        height: auto;
        flex-direction: column;
        display: flex;
        margin-top: 10px;
    }

    .riview2 {
        width: 100%;
        height: 300px;
        margin-left: 0px;
        background-color: lightgray;
        margin-top: 10px;
    }

    .riview2 h1 {
        font-size: 25px;
        text-align: center;
        margin-top: 20px;
    }

    .riview2 p {
        margin-left: 10px;
    }

    .riview2 i {
        margin-left: 30px;
        color: red;
    }

}

@media screen and (min-width: 601px) and (max-width: 1250px) {
    .main {
        width: 1250px;
        height: 100%;
    }

    .head {
        width: 100%;
        height: 40px;
        display: flex;
    }

    .head2 {
        width: 50%;
        height: 100%;
        background-color: #ff86ad;
    }

    .social {
        width: 260px;
        background-color: white;
        margin-left: 100px;
        height: 85%;
        margin-top: 03px;
        border-radius: 16px;
    }

    .head3 {
        width: 50%;
        height: 100%;
        background-color: #5e4e6d;

    }

    .marks {
        width: 500px;
        height: 100%;
        margin-left: 100px;
    }

    .marks p {
        color: white;
        font-weight: bold;
        padding-top: 07px;
    }




    .socia2 {
        width: 100%;
        height: 50px;
        margin-top: 0px;
    }

    .socia2 a i {
        border-radius: 5px;
        width: 25px;
        height: 25px;
        margin-left: 15px;
        padding-left: 8px;
        padding-top: 7px;
        margin-top: 04px;
        color: white;
        font-size: 13px;
        transition: transform 0.2s ease;
        /* Added transition for smooth animation */
    }

    .socia2 a i:hover {
        transform: translateY(-5px);
        /* Move the icon 5px up on hover */
    }

    .present {
        width: 100%;
        height: 100px;
        display: flex;
    }

    .present2 {
        width: 23%;
        height: 100%;
        margin-left: 10px;

    }

    .present2 h1 {
        font-size: 16px;
        text-align: center;
        margin-top: 10px;
        font-weight: bold;
    }

    .present2 p {
        margin-left: 80px;
        font-size: 14px;
        margin-top: -02px;
        color: #5e4e6d;
    }

    .logs {
        width: 50%;
        height: 100%;
        margin-top: 20px;
    }

    .logs img {
        margin-left: 80px;
        margin-top: 10px;
    }

    .logs h1 {
        margin-left: -20px;
        margin-top: 10px;
    }

    .disc {
        margin-left: 70px;
        margin-top: -40px;
        color: #ff86ad;
    }



    .icons {
        width: 60px;
        height: 80%;
        margin-top: 10px;
        border: 3px solid #5e4e6d;
        border-radius: 16px;
        text-align: center;
        padding-top: 15px;

    }

    .icons i {
        font-size: 40px;
    }

    .icons:hover {
        border: 3px solid #ff86ad;
        color: #ff86ad;
    }

    .mansi h1 {
        padding-left: 100px;
    }

    .mansi p {
        padding-left: 5px;
    }




    .garima h1 {
        padding-right: 150px;
    }

    .garima p {
        margin-left: -0px;
    }



    .calls h1 {
        padding-right: 190px;
    }

    .calls p {
        margin-left: -0px;
    }

    .riview {
        width: 100%;
        height: 300px;

        display: flex;
        margin-top: 10px;
    }

    .riview2 {
        width: 24.5%;
        height: 100%;
        margin-left: 05px;
        background-color: lightgray;

    }

    .riview2 h1 {
        font-size: 25px;
        text-align: center;
        margin-top: 20px;
    }

    .riview2 p {
        margin-left: 10px;
    }

    .riview2 i {
        margin-left: 30px;
        color: red;
    }


}