.main {
  width: 100%;
  height: 100%;
}

.navbar-brand,
.navbar-nav .nav-link {
  color: black;
  font-size: 16px;
  margin-left: 05px;
  height: 50px;
  font-weight: bold;

}

.navbar {
 border: 1px solid #5e4e6d;

  width: 98%;
  margin-left: 13px;
  border-radius: 10px;
}

.nav-item:hover {
  background-color: #ff86ad;
  hyphens: 55px;
}


.sections {
  width: 90%;
  height: 300px;
  margin-left: 80px;
  display: flex;
  margin-top: 04px;
}

.sect2 {
  width: 30%;
  height: 100%;

}

.sect2 img {
  width: 200px;
  height: 200px;
  margin-left: 140px;
  margin-top: 40px;
}

.sect3 {
  width: 70%;
  height: 100%;

}

.sect3 h1 {
  color: #ff86ad;
  font-weight: bold;
  font-size: 25px;
  margin-top: 40px;
  font-style: italic;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  max-width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}



.about {
  width: 90%;
  height: 500px;
  margin-left: 50px;
  display: flex;
  margin-top: 50px;
}

.about1 {
  width: 30%;
  background-color: #ff86ad;
  height: 100%;
}

.about1 img {
  width: 92%;
  height: 90%;
  padding-left: 20px;
  margin-top: 20px;
}

.about2 {
  width: 70%;

  height: 100%;
}

.about2 h1 {
  text-align: center;
  font-size: 28px;
}

.about2 h2 {
  text-align: center;
  font-size: 19px;
  margin-top: 10px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.about2 h3 {
  text-align: center;
  font-size: 22px;
}

.about2 p {
  margin-left: 20px;
  font-size: 16px;
  color: black;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.about2 button {
  width: 150px;
  height: 45px;
  font-weight: bold;
  background-color: #ff86ad;
  border: none;
  margin-left: 150px;
  border-radius: 10px;
  margin-top: 05px;
}

.about2 button:hover {
  background-color: #5e4e6d;
  color: white;
}

.treatment {
  width: 100%;
  height: auto;
  background-color: #ff86ad;
  margin-top: 20px;
}

.treat {
  width: 60%;
  height: 100%;
  text-align: center;
  margin-left: 250px;
  color: white;
}

.treat h1 {
  padding-top: 20px;
}

.obstic {
  width: 80%;
  height: auto;

  margin-left: 120px;
  margin-top: 30px;
  display: flex;
}

.obstic2 {
  width: 50%;
  height: 100%;

}

.facs {
  width: 100%;
  height: 150px;

}

.facs h1 {
  margin-left: 120px;
  font-size: 22px;
  color: white;
  margin-top: 20px;
}



.facs h1:hover{
  color: black;
  font-size: 25px;
  font-weight: bold;
}

.facs p {
  font-size: 17px;
  color: white;
  margin-left: 120px;
  margin-top: -50px;
}

.facs p:hover{
  color: black;
  font-size: 16px;
  font-weight: bold;
}
.facs img {
  margin-top: -40px;
}

.content {
  width: 95%;
  height: 470px;
  display: flex;
  margin-left: 40px;
}

.content1 {
  width: 30%;
  height: 100%;

}

.content2 {
  width: 68%;
  height: 100%;
  margin-left: 15px;
  background-image: url(img/moms.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.melo {
  width: 450px;
  height: 100%;
}

.melo h1 {
  font-size: 20px;
  text-align: center;
  color: white;
  margin-top: 20px;
}

.melo p {
  color: white;
  font-size: 20px;
  margin-top: 30px;
  padding-left: 10px;
  text-shadow: 5px 5px 4px gray;
}

.content1 {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
select {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

}

button:hover {
  background-color: #0056b3;
}

input[type="text"]:focus,
input[type="email"]:focus,
select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px #007bff;
}

.faq-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 20px;
}

.faq-item {
  margin-bottom: 20px;
  margin-top: 10px;
}

.faq-item h3 {
  font-size: 1.5em;
  color: #5e4e6d;
  font-weight: bold;
}

.faq-item p {
  font-size: 1em;
}


.footer {
  width: 100%;
  height: 500px;
  background-color: #5e4e6d;
}

.footer h1 {
  text-align: center;
  color: white;
  font-size: 32px;
  margin-top: 20px;
}

.footer1 {
  width: 100%;
  height: 400px;

  display: flex;
}

.foot {
  width: 25%;
  height: 100%;

  color: white;
}

.foot h1 {
  font-size: 22px;
}

.link {
  color: white;
  text-decoration: none;
  font-size: 18px;
  margin-top: 20px;
}

.foot p {
  margin-left: 20px;
  margin-top: 20px;
  font-size: 17px;
}

.disclame {
  width: 100%;
  height: 40px;
  background-color: #ff86ad;
}

.disclame p {
  text-align: center;
  padding-top: 10px;
  color: white;
}

.heading {
  width: 100%;
  height: 220px;
  background-image: url(img/about-background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.heading h1 {
  text-align: center;
  padding-top: 50px;
  color: white;
}

.heading p {
  text-align: center;
  color: white;
}

.contat {
  width: 90%;
  height: 480px;
  display: flex;

  margin-left: 70px;
}

.contat2 {
  width: 50%;
  height: 100%;

}

.contat2 h1 {
  text-align: center;
  color: #5e4e6d;
}

.contat2 p {
  font-size: 20px;
  color: black;
  margin-top: 20px;
}

.maps {
  width: 100%;
  height: 400px;
  margin-top: 10px;
}

.gallery {
  width: 100%;
  height: auto;

  display: flex;
  margin-top: 20px;
}

.gallery2 {
  width: 30%;
  height: 100%;
  margin-left: 30px;
}

.gallery2 img {
  width: 100%;
}


.desiese {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 20px;
}

.desiese1 {
  width: 60%;
  height: auto;
}

.desiese1 h1 {
  margin-left: 60px;
  font-size: 25px;
  margin-top: 20px;
}

.desiese1 p {
  margin-left: 60px;
  font-size: 15px;
}

.desiese1 li {
  margin-left: 60px;
}

.desiese2 {
  width: 19%;
  height: auto;
  background-color: #ff86ad;
  margin-left: 10px;
}

.desiese2 h1 {
  font-size: 25px;
  text-align: center;
  margin-top: 30px;
  color: white;
}

.desiese2 h2 {
  font-size: 16px;
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
  color: white;
}

.desiese2 p {
  margin-left: 20px;
  font-size: 16px;
  color: white;
}


.desiese3 {
  width: 19%;
  height: auto;
  background-color: #ff86ad;
  margin-left: 10px;
}

.desiese3 h1 {
  font-size: 25px;
  text-align: center;
  margin-top: 30px;
  color: white;
}


.desiese3 h2 {
  font-size: 16px;
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
  color: white;
}

.desiese3 p {
  margin-left: 20px;
  font-size: 16px;
  color: white;
}


@media screen and (min-width: 300px) and (max-width: 600px) {
  .main {
    width: 100%;
    height: 100%;
  }


  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;
    font-size: 17px;
    margin-left: 0px;
    height: 52px;
  }

  .navbar {
    border: 1px solid #5e4e6d;
    width: 100%;
    margin-left: 0px;
    border-radius: 10px;

  }

  .nav-item:hover {
    background-color: #ff86ad;
    hyphens: 55px;
  }

  .container-fluid img {
   height: 100%;
   margin-top: 10px;
  }

  .sections {
    width: 97%;
    height: auto;
    margin-left: 0px;
    display: flex;
    margin-top: 04px;
  }

  .sect2 {
    width: 30%;
    height: 100%;

  }

  .sect2 img {
    width: 100px;
    height: 100px;
    margin-left: 10px;
    margin-top: 50px;
  }

  .sect3 {
    width: 70%;
    height: 100%;

  }

  .sect3 h1 {
    color: #ff86ad;
    margin-left: 05px;
    font-weight: bold;
    font-size: 13px;
    margin-top: 20px;
    font-style: italic;

  }

  .about {
    width: 100%;
    height: auto;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .about1 {
    width: 100%;
    background-color: #ff86ad;
    height: 100%;
  }

  .about1 img {
    width: 95%;
    height: 90%;
    padding-left: 20px;
    margin-top: 20px;
  }

  .about2 {
    width: 100%;
    height: 100%;
  }

  .about2 h1 {
    text-align: center;
    font-size: 28px;
  }




  .about2 h2 {
    text-align: center;
    font-size: 19px;
    margin-top: 10px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .about2 h3 {
    text-align: center;
    font-size: 22px;
  }

  .about2 p {
    margin-left: 20px;
    font-size: 16px;
    color: black;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .about2 button {
    width: 150px;
    height: 40px;
    font-weight: bold;
    background-color: #ff86ad;
    border: none;
    margin-left: 40px;
    border-radius: 10px;
    margin-top: 05px;
  }

  .about2 button:hover {
    background-color: #5e4e6d;
    color: white;
  }

  .treatment {
    width: 100%;
    height: auto;
    background-color: #ff86ad;
    margin-top: 20px;
  }

  .treat {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-left: 0px;
    color: white;
  }

  .treat h1 {
    padding-top: 20px;
  }

  .obstic {
    width: 100%;
    height: auto;
    flex-direction: column;
    margin-left: 0px;
    margin-top: 30px;
    display: flex;
  }

  .obstic2 {
    width: 100%;
    height: 100%;

  }

  .facs {
    width: 100%;
    height: 150px;

  }

  .facs h1 {
    margin-left: 105px;
    font-size: 22px;
    color: white;
    margin-top: 20px;
  }

  .facs p {
    font-size: 17px;
    color: white;
    margin-left: 100px;
    margin-top: -50px;
  }

  .facs img {
    margin-top: -40px;
  }

  .content {
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
    margin-left: 0px;
  }

  .content1 {
    width: 100%;
    height: 100%;

  }

  .content2 {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    background-image: url(img/moms.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .melo {
    width: 100%;
    height: 100%;
  }

  .melo h1 {
    font-size: 20px;
    text-align: center;
    color: white;
    margin-top: 20px;
  }

  .melo p {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-top: 30px;
    padding-left: 10px;
    text-shadow: 5px 5px 4px gray;
  }

  .content1 {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 5px;
  }

  input[type="text"],
  input[type="email"],
  select {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

  }

  button:hover {
    background-color: #0056b3;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px #007bff;
  }

  .faq-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    margin-top: 20px;
  }

  .faq-item {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .faq-item h3 {
    font-size: 1em;
    color: #5e4e6d;
    font-weight: bold;
  }

  .faq-item p {
    font-size: 1em;
  }


  .footer {
    width: 100%;
    height: auto;
    background-color: #5e4e6d;
  }

  .footer h1 {
    text-align: center;
    color: white;
    font-size: 32px;
    margin-top: 20px;
  }

  .footer1 {
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
  }

  .foot {
    width: 100%;
    height: 100%;

    color: white;
  }

  .foot h1 {
    font-size: 22px;
  }

  .link {
    color: white;
    text-decoration: none;
    font-size: 18px;
    margin-top: 20px;
  }

  .foot p {
    margin-left: 20px;
    margin-top: 20px;
    font-size: 17px;
  }

  .disclame {
    width: 100%;
    height: 60px;
    background-color: #ff86ad;
  }

  .disclame p {
    text-align: center;
    padding-top: 10px;
    color: white;
    font-size: 16px;
  }


  .heading {
    width: 100%;
    height: 110px;
    background-image: url(img/about-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .heading h1 {
    text-align: center;
    padding-top: 20px;
    color: white;
  }

  .heading p {
    text-align: center;
    color: white;
  }


  .contat {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }

  .contat2 {
    width: 100%;
    height: 100%;

  }

  .contat2 h1 {
    text-align: center;
    color: #5e4e6d;
    margin-left: 10px;
  }

  .contat2 p {
    font-size: 20px;
    color: black;
    margin-top: 20px;
    margin-left: 10px;
  }

  .maps {
    width: 95%;
    height: 300px;
    margin-top: 10px;
    margin-left: 10px;
  }

  .gallery {
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
    margin-top: 20px;
  }

  .gallery2 {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }

  .gallery2 img {
    width: 100%;
  }


  .desiese {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .desiese1 {
    width: 100%;
    height: auto;
  }

  .desiese1 h1 {
    margin-left: 20px;
    font-size: 25px;
    margin-top: 20px;
  }

  .desiese1 p {
    margin-left: 20px;
    font-size: 15px;
  }

  .desiese1 li {
    margin-left: 20px;
  }

  .desiese2 {
    width: 100%;
    height: auto;
    background-color: #ff86ad;
    margin-left: 0px;
  }

  .desiese2 h1 {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    color: white;
  }

  .desiese2 h2 {
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    color: white;
  }

  .desiese2 p {
    margin-left: 20px;
    font-size: 16px;
    color: white;
  }


  .desiese3 {
    width: 100%;
    height: auto;
    background-color: #ff86ad;
    margin-left: 0px;
  }

  .desiese3 h1 {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    color: white;
  }


  .desiese3 h2 {
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    color: white;
  }

  .desiese3 p {
    margin-left: 20px;
    font-size: 16px;
    color: white;
  }

}

@media screen and (min-width: 601px) and (max-width: 1250px) {
  .main {
    width: 1250px;
    height: 100%;
  }

  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;
    font-size: 17px;
    margin-left: 0px;
    height: 52px;
  }


  .navbar { 
     border: 1px solid #5e4e6d;
    width: 98%;
    margin-left: 13px;
    border-radius: 10px;
  }

  .nav-item:hover {
    background-color: #ff86ad;
    hyphens: 55px;
  }

  .sections {
    width: 90%;
    height: 300px;
    margin-left: 80px;
    display: flex;
    margin-top: 04px;
  }

  .sect2 {
    width: 30%;
    height: 100%;

  }

  .sect2 img {
    width: 200px;
    height: 200px;
    margin-left: 140px;
    margin-top: 40px;
  }

  .sect3 {
    width: 70%;
    height: 100%;

  }

  .sect3 h1 {
    color: #ff86ad;
    font-weight: bold;
    font-size: 25px;
    margin-top: 40px;
    font-style: italic;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    max-width: 100%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .about {
    width: 90%;
    height: 500px;
    margin-left: 50px;
    display: flex;
    margin-top: 50px;
  }

  .about1 {
    width: 30%;
    background-color: #ff86ad;
    height: 100%;
  }

  .about1 img {
    width: 92%;
    height: 90%;
    padding-left: 20px;
    margin-top: 20px;
  }

  .about2 {
    width: 70%;

    height: 100%;
  }

  .about2 h1 {
    text-align: center;
    font-size: 28px;
  }

  .about2 h2 {
    text-align: center;
    font-size: 19px;
    margin-top: 10px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .about2 h3 {
    text-align: center;
    font-size: 22px;
  }

  .about2 p {
    margin-left: 20px;
    font-size: 16px;
    color: black;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .about2 button {
    width: 150px;
    height: 40px;
    font-weight: bold;
    background-color: #ff86ad;
    border: none;
    margin-left: 150px;
    border-radius: 10px;
    margin-top: 05px;
  }

  .about2 button:hover {
    background-color: #5e4e6d;
    color: white;
  }

  .treatment {
    width: 100%;
    height: auto;
    background-color: #ff86ad;
    margin-top: 20px;
  }

  .treat {
    width: 60%;
    height: 100%;
    text-align: center;
    margin-left: 250px;
    color: white;
  }

  .treat h1 {
    padding-top: 20px;
  }

  .obstic {
    width: 80%;
    height: auto;

    margin-left: 120px;
    margin-top: 30px;
    display: flex;
  }

  .obstic2 {
    width: 50%;
    height: 100%;

  }

  .facs {
    width: 100%;
    height: 150px;

  }

  .facs h1 {
    margin-left: 120px;
    font-size: 22px;
    color: white;
    margin-top: 20px;
  }

  .facs p {
    font-size: 17px;
    color: white;
    margin-left: 120px;
    margin-top: -50px;
  }

  .facs img {
    margin-top: -40px;
  }

  .content {
    width: 95%;
    height: 470px;
    display: flex;
    margin-left: 40px;
  }

  .content1 {
    width: 30%;
    height: 100%;

  }

  .content2 {
    width: 68%;
    height: 100%;
    margin-left: 15px;
    background-image: url(img/moms.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .melo {
    width: 450px;
    height: 100%;
  }

  .melo h1 {
    font-size: 20px;
    text-align: center;
    color: white;
    margin-top: 20px;
  }

  .melo p {
    color: white;
    font-size: 20px;
    margin-top: 30px;
    padding-left: 10px;
    text-shadow: 5px 5px 4px gray;
  }

  .content1 {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 5px;
  }

  input[type="text"],
  input[type="email"],
  select {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

  }

  button:hover {
    background-color: #0056b3;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px #007bff;
  }

  .faq-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 20px;
  }

  .faq-item {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .faq-item h3 {
    font-size: 1.5em;
    color: #5e4e6d;
    font-weight: bold;
  }

  .faq-item p {
    font-size: 1em;
  }


  .footer {
    width: 100%;
    height: 500px;
    background-color: #5e4e6d;
  }

  .footer h1 {
    text-align: center;
    color: white;
    font-size: 32px;
    margin-top: 20px;
  }

  .footer1 {
    width: 100%;
    height: 400px;

    display: flex;
  }

  .foot {
    width: 25%;
    height: 100%;

    color: white;
  }

  .foot h1 {
    font-size: 22px;
  }

  .link {
    color: white;
    text-decoration: none;
    font-size: 18px;
    margin-top: 20px;
  }

  .foot p {
    margin-left: 20px;
    margin-top: 20px;
    font-size: 17px;
  }

  .disclame {
    width: 100%;
    height: 40px;
    background-color: #ff86ad;
  }

  .disclame p {
    text-align: center;
    padding-top: 10px;
    color: white;
  }

  .heading {
    width: 100%;
    height: 220px;
    background-image: url(img/about-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .heading h1 {
    text-align: center;
    padding-top: 50px;
    color: white;
  }

  .heading p {
    text-align: center;
    color: white;
  }

  .contat {
    width: 90%;
    height: 480px;
    display: flex;

    margin-left: 70px;
  }

  .contat2 {
    width: 50%;
    height: 100%;

  }

  .contat2 h1 {
    text-align: center;
    color: #5e4e6d;
  }

  .contat2 p {
    font-size: 20px;
    color: black;
    margin-top: 20px;
  }

  .maps {
    width: 100%;
    height: 400px;
    margin-top: 10px;
  }

  .gallery {
    width: 100%;
    height: auto;

    display: flex;
    margin-top: 20px;
  }

  .gallery2 {
    width: 30%;
    height: 100%;
    margin-left: 30px;
  }

  .gallery2 img {
    width: 100%;
  }


  .desiese {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 20px;
  }

  .desiese1 {
    width: 60%;
    height: auto;
  }

  .desiese1 h1 {
    margin-left: 60px;
    font-size: 25px;
    margin-top: 20px;
  }

  .desiese1 p {
    margin-left: 60px;
    font-size: 15px;
  }

  .desiese1 li {
    margin-left: 60px;
  }

  .desiese2 {
    width: 19%;
    height: auto;
    background-color: #ff86ad;
    margin-left: 10px;
  }

  .desiese2 h1 {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    color: white;
  }

  .desiese2 h2 {
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    color: white;
  }

  .desiese2 p {
    margin-left: 20px;
    font-size: 16px;
    color: white;
  }


  .desiese3 {
    width: 19%;
    height: auto;
    background-color: #ff86ad;
    margin-left: 10px;
  }

  .desiese3 h1 {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    color: white;
  }


  .desiese3 h2 {
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    color: white;
  }

  .desiese3 p {
    margin-left: 20px;
    font-size: 16px;
    color: white;
  }
}